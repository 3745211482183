<template>
<div>
  <youtube :video-id="videoIds[Math.floor(Math.random() * videoIds.length)]" @ready="ready" ref="youtube" :playerVars="{autoplay: 1, loop: 1}" width="1920" height="1080"></youtube>
</div>
</template>



<script>
export default {
  data() {
    return {
      orderId: '',
      videoIds: ['gFOgPlhAsMc', '__l-8sH950s', 'SBRhFbiy_XI']
    }
  },
  methods: {
    ready () {
      // this.player.setVolume(100);
    },
  },
  computed:{
      player(){
          return this.$refs.youtube.player
      }
  },
  mounted() {
    this.orderId = this.$route.query.order_id
  }
};
</script>
<template>
  <content>
    <router-view></router-view>
  </content>
</template>

<script>
export default {
  name: "App",
  components: {},
  data: () => ({})
};
</script>
<style module>
body {
  background-color: #060606 !important;
  color: #ffff !important;
  overflow-x: hidden;
}
</style>

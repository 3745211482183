<template>
  <body>
    <div id="header__vm">
      <img src="../../assets/header_logo.png" alt="logo" />
      <div id="header_title">
        <span>KIRINの英知を集約した革新的な栄養体験</span>
      </div>
    </div>
    <div id="main">
      <div id="main_logo">
        <img src="../../assets/main_logo.png" alt="logo" />
      </div>
      <div id="Break_The_Limit">
        <span>Break The Limit.</span>
      </div>
      <div style="text-align: center;">
        <h2>サービス提供中（アプリ無料）</h2>
        <p>※有料のサプリメントサーバサービスは下記店舗でご利用できます</p>
        <p>・GOLD’S GYM 原宿東京店</p>
        <p>・GOLD’S GYM 原宿ANNEX店</p>
        <p>・GOLD’S GYM 渋谷東京店</p>
      </div>
      <div id="contact">
        <p>お問い合わせ先 : GOLD EXPERIENCE事業事務局</p>
        <a href="mailto:goldexperience@kirin.co.jp"
          ><span class="mail_icon"></span>goldexperience@kirin.co.jp</a
        >
      </div>
      <div class="app_downloads">
        <img src="../../assets/app_downloads_QR.png" alt="logo" />
        <br />
      </div>
      <div class="machine_content"></div>
    </div>
  </body>
</template>
<script>
export default {
  name: "Waiting",
  mounted() {},
  head: {
    title: {
      inner: "GOLD EXPERIENCE"
    },
    meta: [
      { name: "title", content: "GOLD EXPERIENCE" },
      { name: "description", content: "KIRINの英知を集約した革新的な栄養体験" },
      { charset: "utf-8" },
      { property: "og:type", content: "website" },
      {
        property: "og:description",
        content: "KIRINの英知を集約した革新的な栄養体験"
      },
      { property: "og:title", content: "GOLD EXPERIENCE" },
      { property: "og:url", content: "https://goldexperience.kirin.co.jp" },
      {
        property: "og:image",
        content: "https://goldexperience.kirin.co.jp/ogp.png"
      },
      { property: "og:site_name", content: "GOLD EXPERIENCE" }
    ],
    script: [
      {
        type: "text/javascript",
        src: "https://www.kirinholdings.com/share/ga/code.js",
        async: true
      }
    ]
  }
};
</script>

<style>
@import url(http://fonts.googleapis.com/earlyaccess/notosansjp.css);
#header__vm > a {
  position: absolute;
  top: 18px;
  right: 30px;
  left: auto;
  color: white;
}
#header__vm img {
  position: absolute;
  width: 60px;
  height: 31.262px;
  left: 40.012px;
  top: 17px;
  overflow: visible;
}
#header__vm {
  background-color: #4d5258;
  position: absolute;
  width: 100%;
  height: 65px;
  left: 0px;
  top: 0px;
  overflow: visible;
  z-index: 99;
}
#header__vm #header_title {
  opacity: 0.8;
  margin-top: 20px;
  margin-left: 120px;
  overflow: visible;
  width: 289px;
  white-space: nowrap;
  line-height: 24px;
  text-align: left;
  font-family: "Noto Sans JP", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  color: rgba(255, 255, 255, 1);
  letter-spacing: 1px;
}
.app_downloads {
  position: absolute;
  left: 30px;
  bottom: 30px;
}
.app_downloads > a > img {
  padding-bottom: 10px;
  width: 150px;
}
#main {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  background-image: url("../../assets/supplement_lp_mv.png");
  height: 100vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-size: contain;
  background-size: cover;
  position: relative;
  font-family: "Noto Sans JP", sans-serif;
}

#main_logo > img {
  margin-left: auto;
  margin-right: auto;
}

#Break_The_Limit {
  overflow: visible;
  width: 100%;
  white-space: nowrap;
  text-align: center;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
  font-size: 25px;
  color: rgba(166, 156, 125, 1);
  letter-spacing: 1px;
  margin: 30px auto;
}
#contact {
  padding: 20px 80px;
  background: rgba(77, 82, 88, 0.5);
  text-align: center;
}

#contact > h3 {
  color: white;
  font-size: 25px;
  margin: 10px auto;
  font-family: Noto Sans JP;
  font-style: normal;
  font-weight: normal;
}

#contact > p {
  font-size: 14px;
  color: white;
  padding: 0;
  margin: 10px auto;
}

#contact > a {
  font-size: 14px;
  color: white;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: flex-end;
  -ms-flex-align: flex-end;
  align-items: flex-end;
  -webkit-justify-content: center;
  justify-content: center;
}

.mail_icon {
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/mail_icon.png");
  width: 23px;
  height: 15px;
  display: block;
  margin-right: 10px;
}

.machine_content {
  position: absolute;
  background-size: cover;
  background-repeat: no-repeat;
  background-image: url("../../assets/machine.png");
  width: 209px;
  height: 317px;
  right: 30px;
  bottom: 0px;
}

@media screen and (max-width: 768px) {
  #header__vm > a > img#qa {
    width: 30px;
    height: 30px;
    top: 10px;
    right: 15px;
  }
  #header__vm img {
    width: 45px;
    height: 24px;
    left: 20px;
    top: 15px;
    overflow: visible;
  }
  #header__vm {
    height: 50px;
  }
  #header__vm #header_title {
    display: none;
  }

  #main_logo {
    width: 80%;
  }

  #main_logo > img {
    width: 100%;
  }

  #Break_The_Limit {
    font-size: 18px;
    color: rgba(166, 156, 125, 1);
    letter-spacing: 1px;
    margin: 30px auto;
  }

  #contact {
    padding: 15px 30px;
  }

  #contact > h3 {
    font-size: 16px;
    margin: 10px auto;
  }

  #contact > p {
    font-size: 12px;
    margin: 10px auto;
  }

  #contact > a {
    font-size: 12px;
  }
  #info {
    margin: 0 20px;
  }
  .mail_icon {
    width: 18px;
    height: 12px;
    margin-right: 5px;
  }

  .machine_content {
    width: 110px;
    height: 167px;
    right: 10px !important;
    bottom: 0px;
  }
}
</style>

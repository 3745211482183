<template> 
  <Serving /> 
</template> 

<script> 
  import Serving from '../../components/vm/Serving'  
  export default { 
    components: { 
      Serving 
    } 
  } 
</script> 
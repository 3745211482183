<template> 
  <LandingPage2 /> 
</template> 

<script> 
  import LandingPage2 from '../components/LandingPage2'  
  export default { 
    components: { 
      LandingPage2 
    } 
  } 
</script> 
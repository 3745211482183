<template> 
  <Gxcharge2407Cp /> 
</template> 

<script> 
  import Gxcharge2407Cp from '../components/Gxcharge2407Cp'  
  export default { 
    components: { 
      Gxcharge2407Cp 
    } 
  } 
</script> 
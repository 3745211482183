<template> 
  <LandingPageAs /> 
</template> 

<script> 
  import LandingPageAs from '../components/LandingPageAs'  
  export default { 
    components: { 
      LandingPageAs 
    } 
  } 
</script> 
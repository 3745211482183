<template> 
  <LandingPage /> 
</template> 

<script> 
  import LandingPage from '../components/LandingPage'  
  export default { 
    components: { 
      LandingPage 
    } 
  } 
</script> 
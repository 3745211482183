<template>
<body>
  <noscript><iframe src="https://www.googletagmanager.com/ns.html?id=GTM-WXX7CQZS"
height="0" width="0" style="display:none;visibility:hidden"></iframe></noscript>
  <div id="app">
    <div class="image-container" v-for="image in images" :key="image.id">
      <img :src="image.url" :alt="image.alt">
      <a target="_blank" :href="image.link" class="button" v-if="image.buttonImage" :class="image.addClass">
        <img :src="image.buttonImage">
      </a>
    </div>
  </div>
</body>
</template>

<script>

export default {
  name: "Gxboost2407Cp",
  mounted() {
    this.addGtmScript();
  },
  methods: {
    addGtmScript() {
      const script = document.createElement('script');
      script.text = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-WXX7CQZS');`;
      document.head.appendChild(script);
    }
  },
  data() {
    return { 
      images: [
        { id: 1, url: require('@/assets/gxboost_2407cp/fv_c.png'), alt: '説明1' },
        { id: 2, url: require('@/assets/gxboost_2407cp/banner_01.png'), addClass: 'small', link: 'https://www.amazon.co.jp/gp/product/B0C6XND3QT', buttonImage: require('@/assets/gxboost_2407cp/button.png'), alt: '説明2' },
          { id: 1, url: require('@/assets/gxboost_2407cp/lp_01.png'), alt: '説明1' },
          { id: 2, url: require('@/assets/gxboost_2407cp/banner_01.png'), addClass: 'small', link: 'https://www.amazon.co.jp/gp/product/B0C6XND3QT', buttonImage: require('@/assets/gxboost_2407cp/button.png'), alt: '説明2' },
          { id: 2, url: require('@/assets/gxboost_2407cp/lp_02.png'), alt: '説明2' },
          { id: 2, url: require('@/assets/gxboost_2407cp/banner_01.png'), addClass: 'small', link: 'https://www.amazon.co.jp/gp/product/B0C6XND3QT', buttonImage: require('@/assets/gxboost_2407cp/button.png'), alt: '説明2' },
          { id: 2, url: require('@/assets/gxboost_2407cp/lp_03.png'), alt: '説明2' },
          { id: 2, url: require('@/assets/gxboost_2407cp/banner_01.png'), addClass: 'small',link: 'https://www.amazon.co.jp/gp/product/B0C6XND3QT', buttonImage: require('@/assets/gxboost_2407cp/button.png'),  alt: '説明2' },
          // 他の画像情報を追加
        ],
        specificIndex: 2 // ボタンを表示したい画像のインデックス
    }
  }
}
</script>
<style>
.image-container {
  position: relative;
  display: block; /* コンテナをブロック要素として扱い */
  margin-left: auto;
  margin-right: auto;
  max-width: 600px; /* 最大幅を600pxに設定 */
  width: 100%; /* 幅を100%に設定して、max-widthと合わせて使用する */
}

.image-container img {
  width: 100%; /* 画像がコンテナの幅に合わせて拡縮されるようにする */
  height: auto; /* 画像の高さを自動調整してアスペクト比を保持 */
}

.button {
  position: absolute;
  right: 10px;
  padding: 10px;
  width: 30%; /* ボタンの幅を内容に応じて自動調整 */
}
.small {
    bottom: 68px;
  }
  .large {
    bottom: 8vh;
  }
@media (min-width: 1024px) {
  /* 画面幅が1024px以上の場合のスタイル */
  .button {
    right: 20px;
    /* 必要に応じてPC向けのスタイルをここに追加 */
  }

  .small {
    bottom: 104px;
  }
  .large {
    bottom: 12vh;
  }
}
</style>
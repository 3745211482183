<template>
<div width="1920" height="1080">
  <video  ref="videoRef" src="@/assets/complete.mp4" autoplay muted></video>
</div>
</template>
<script>
export default {
  name: 'Complete',
  mounted(){
    this.$refs.videoRef.play();
  },
}
</script>

<template> 
  <Gxboost2407Cp /> 
</template> 

<script> 
  import Gxboost2407Cp from '../components/Gxboost2407Cp'
  export default { 
    components: { 
      Gxboost2407Cp
    } 
  } 
</script> 
<template> 
  <Complete /> 
</template> 

<script> 
  import Complete from '../../components/vm/Complete'  
  export default { 
    components: { 
      Complete 
    } 
  } 
</script> 
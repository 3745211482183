<template> 
  <QAView /> 
</template> 

<script> 
  import QAView from '../../components/app/QAView'  
  export default { 
    components: { 
      QAView 
    } 
  } 
</script> 
<template> 
  <Downloads /> 
</template> 

<script> 
  import Downloads from '../../components/app/Downloads'  
  export default { 
    components: { 
      Downloads 
    } 
  } 
</script> 
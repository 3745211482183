<template>
  <div style="">
    <Header />

    <div class="wrap_main">
      <ul style="margin-top: 100px;">
        <li><h4>アプリについて</h4></li>
        <li><QSvg /> 利用料はかかりますか？</li>
        <li>
          <ASvg />
          アプリのご利用は無料です。※機器や通信にかかる費用はお客様のご負担になります。
        </li>
      </ul>
      <hr />
      <ul>
        <li><h4>製造場所について</h4></li>
        <li><QSvg /> サプリメントの製造場所はどこですか？</li>
        <li>
          <ASvg />
          国内のGMP認証工場にて製造し、キリンホールディングス株式会社が販売しています。
        </li>
      </ul>
      <hr />
      <ul>
        <li><h4>アレルギーについて</h4></li>
        <li><QSvg /> アレルギーは含まれていますか？</li>
        <li><ASvg /> アレルギー物質28品目は不使用です。</li>
      </ul>
      <hr />
      <ul>
        <li><h4>プロテインとアミノ酸の違いについて教えてください。</h4></li>
        <li><QSvg /> プロテインとアミノ酸の違いについて教えてください。</li>
        <li>
          <ASvg />
          アミノ酸は体内で行われるたんぱく質を分解したもののため吸収の効率に優れています。またアミノ酸にはそれ自身で機能性を持つため、その人の目的に応じたに必要な組み合わせを行うことで、より効果的な栄養摂取を実現できます。
        </li>
      </ul>
      <hr />
      <ul>
        <li><h4>ポイントの有効期限について</h4></li>
        <li><QSvg /> ポイントの有効期限はいつまでですか？</li>
        <li><ASvg /> ポイントの有効期限は付与日より3ヵ月以内になります。</li>
      </ul>
      <hr />
      <ul>
        <li><h4>革新的な栄養体験について</h4></li>
        <li><QSvg /> 革新的な栄養体験とはどのような体験ですか？</li>
        <li>
          <ASvg />
          有料サービスであるプロフェッショナルサプリメントサーバサービスは、これまでのサプリメントとは異なり、本アプリにてお客様自身が自由に素材を配合し、専用サーバを通してサプリメントをご利用できるサービスです。
        </li>
      </ul>
      <hr />
      <ul>
        <li><h4>サービス共通</h4></li>
        <li>
          <QSvg />
          アプリの退会や有料サービスの解約手続きはどのようにすれば良いですか？
        </li>
        <li id="subscription_cancel">
          <ASvg />
          アプリから解約が可能です。
          <br/>
          有料会員の解約：アプリの会員情報、サプリメントサービスプランの変更より
          <br/>
          アプリ無料会員：アプリ右上メニューより
          <br/>
          ※アプリは最新版への更新お願いいたします
        </li>
      </ul>
    </div>
    <Footer />
  </div>
</template>
<script>
import QSvg from "../../assets/q.svg";
import ASvg from "../../assets/a.svg";
export default {
  name: "QAPage",
  components: {
    QSvg,
    ASvg,
    Header: () => import("../home/Header.vue"),
    Footer: () => import("../home/Footer.vue")
  }
};
</script>
<style scoped>
li {
  margin-bottom: 16px;
  font-size: 0.8rem;
}

ul {
  margin: 2rem 2rem 2rem 0;
  max-width: 1020px;
}

hr {
  color: #a69c7d;
  opacity: 1;
}

h4 {
  font-weight: bold;
  font-size: 1rem;
}

.wrap_main {
  padding-top: 3rem;
  max-width: 1020px;
  margin: auto;
}
</style>

<template>
  <body>
    <Header />
    <MainView />
    <SectionWhat />
    <SectionVision />
    <SectionFeature />
    <SectionProduct />
    <SectionStore />
    <SectionFeatureDesc />
    <Footer />
  </body>
</template>
<script>
export default {
  components: {
    Header: () => import("./home/Header.vue"),
    MainView: () => import("./home/MainView.vue"),
    SectionWhat: () => import("./home/SectionWhat.vue"),
    SectionVision: () => import("./home/SectionVision.vue"),
    SectionFeature: () => import("./home/SectionFeature.vue"),
    SectionProduct: () => import("./home/SectionProduct.vue"),
    SectionStore: () => import("./home/SectionStore.vue"),
    SectionFeatureDesc: () => import("./home/SectionFeatureDesc.vue"),
    Footer: () => import("./home/Footer.vue")
  },
  name: "Home",
  mounted() {},
  head: {
    title: {
      inner: "GOLD EXPERIENCE"
    },
    meta: [
      { name: "title", content: "GOLD EXPERIENCE" },
      {
        name: "description",
        content:
          "KIRINの英知を集約した革新的な栄養体験 2021年9月サービス開始予定"
      },
      { charset: "utf-8" },
      { property: "og:type", content: "website" },
      {
        property: "og:description",
        content:
          "KIRINの英知を集約した革新的な栄養体験 2021年9月サービス開始予定"
      },
      { property: "og:title", content: "GOLD EXPERIENCE" },
      { property: "og:url", content: "https://goldexperience.kirin.co.jp" },
      {
        property: "og:image",
        content: "https://goldexperience.kirin.co.jp/ogp.png"
      },
      { property: "og:site_name", content: "GOLD EXPERIENCE" }
    ],
    script: [
      {
        type: "text/javascript",
        src: "https://www.kirinholdings.com/share/ga/code.js",
        async: true
      }
    ]
  }
};
</script>

<style>
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
/* reset */
/* -=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=--=-=-=- */
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
canvas,
caption,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
main,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
summary,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video,
button {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
ul {
  list-style: none;
  padding: 0;
}
small {
  font-size: unset;
}
input,
button {
  outline: none;
}
a {
  margin: 0;
  padding: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}

a:link {
  text-decoration: none;
}

.section__head {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  margin: 0 auto 30px;
}

.section__head::after,
.section__head::before {
  content: "";
  background-image: url("../assets/lp_new/section_head_item.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  width: 20px;
  height: 30px;
  display: block;
}

.section__head h3 {
  text-align: center;
  font-size: 32px;
  font-weight: bold;
  line-height: 60px;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-orient: vertical;
  color: #a69c7d;
  letter-spacing: 2px;
  padding: 0 30px;
}

.section__head h3 span {
  display: block;
  width: 30px;
}
</style>

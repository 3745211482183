<template> 
  <Waiting /> 
</template> 

<script> 
  import Waiting from '../../components/vm/Waiting'  
  export default { 
    components: { 
      Waiting 
    } 
  } 
</script> 
import Vue from 'vue'; 
import Router from 'vue-router';
import Home from './views/Home.vue';
import LandingPage from './views/LandingPage.vue';
import LandingPage2 from './views/LandingPage2.vue';
import LandingPageAs from './views/LandingPageAs.vue';
import Gxcharge2407CpPage from './views/Gxcharge2407CpPage.vue';
import Gxboost2407CpPage from './views/Gxboost2407CpPage.vue';
import Waiting from './views/vm/Waiting.vue';
import Serving from './views/vm/Serving.vue';
import Complete from './views/vm/Complete.vue';
import QAView from './views/app/QAView.vue';
import Downloads from './views/app/Downloads.vue';

Vue.use(Router); 

export default new Router({
  mode: 'history',
  routes: [ 
    { 
      path: '/', 
      name: 'home', 
      component: Home,
      meta: { title: 'GOLD EXPERIENCE', desc: 'GOLD EXPERIENCE' }
    }, 
    { 
      path: '/vm/waiting', 
      name: 'waiting', 
      component: Waiting, 
    }, 
    { 
      path: '/vm/serving', 
      name: 'serving', 
      component: Serving, 
    }, 
    {
      path: '/vm/complete', 
      name: 'complete', 
      component: Complete, 
    }, 
    {
      path: '/app/qa', 
      name: 'qa_view', 
      component: QAView, 
    }, 
    {
      path: '/app/downloads', 
      name: 'add_downloads', 
      component: Downloads, 
    }, 
    {
      path: '/cp/gxcharge_1',
      name: 'LP',
      component: LandingPage,
    },
    {
      path: '/cp/gxcharge_2',
      name: 'LP2',
      component: LandingPage2,
    },
    {
      path: '/cp/gxcharge_as',
      name: 'LPAs',
      component: LandingPageAs,
    },
    {
      path: '/cp/gxcharge_2407cp',
      name: 'Gxcharge2407CpPage',
      component: Gxcharge2407CpPage,
    },
    {
      path: '/cp/gxboost_2407cp',
      name: 'Gxboost2407CpPage',
      component: Gxboost2407CpPage,
    },
    {
      path: '/subscription_cancel',
      name: 'subscription_cancel',
      beforeEnter() {
        window.location = "/app/qa#subscription_cancel"
      }
    }
  ]
});